'use client'

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslations } from 'next-intl'

import { CartItemFragment, useCartQuery } from '@/api'
import { useAddToCart } from './hooks'
import { AddToCartParams, AddToCartTrackingConfig } from './cart.types'
import { trackAddToCartEvent } from './cart.utils'
import { clientFetch } from '@/common/api/rest/fetch.client'
import { HTTP_METHOD } from '@/common/api/api.utils'
import { useFlashMessagesContext } from '../flash-messages'

type CartContext = {
  hasItems: boolean
  cartItems: CartItemFragment[]
  isCartFetchError: boolean
  isCartRefetching: boolean
  isAddingToCart: boolean
  addToCart?: (
    params: AddToCartParams,
    trackingConfig?: AddToCartTrackingConfig,
  ) => Promise<void>
  refetchCartData: () => void
}

export const CartContext = createContext<CartContext>({} as CartContext)

/**
 * Provider that handles the cart
 * @param storeConfig
 * @param children
 * @constructor
 */
export const CartContextProvider = ({ children }: PropsWithChildren) => {
  const t = useTranslations('FlashMessage')
  const { addFlashMessage } = useFlashMessagesContext()
  const { isAddingToCart, addToCart, isCartFetchError } = useAddToCart()

  const { data: currCartId, refetch: refetchCartId } = useQuery<string>({
    queryKey: [],
    queryFn: async () => {
      const res = await clientFetch<string>({
        url: '/rest/V1/guest-carts-session',
        method: HTTP_METHOD.POST,
      })

      return res || ''
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const {
    data: cartData,
    isLoading: isLoadingCartData,
    refetch: refetchCartData,
  } = useCartQuery(
    { cartId: currCartId ?? '' },
    {
      enabled: (currCartId?.length ?? 0) > 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  const addToCartWithCartId = useCallback(
    async (
      params: AddToCartParams,
      trackingConfig?: AddToCartTrackingConfig,
    ) => {
      const productInfo: AddToCartParams = {
        productName: params.productName ?? '',
        parentSku: params.parentSku,
        sku: params.sku ?? '',
        quantity: params.quantity ?? 1,
        brand: params.brand,
        price: params.price,
        category: params.category,
        selectedOptions: params.selectedOptions,
        isGiftCard: params.isGiftCard,
        configId: params.configId,
        simpleId: params.simpleId,
      }

      try {
        await addToCart({
          cartId: currCartId ?? '',
          ...productInfo,
        })

        params.onSuccess?.()

        const firstMessage = t.rich('addToCart', {
          productName: productInfo.productName,
          cart: (chunk) => `<a href="/checkout/cart">${chunk}</a>`,
        })

        addFlashMessage({
          severity: 'success',
          text: firstMessage?.toString() ?? '',
        })

        if (!trackingConfig?.skipTracking) {
          trackAddToCartEvent(
            params,
            trackingConfig?.addingRecommendedProduct ?? false,
          )
        }
      } catch (err) {
        await refetchCartId()
      }

      if ((currCartId?.length ?? 0) === 0) {
        await refetchCartId()
      }

      refetchCartData()
    },

    [currCartId, addToCart, refetchCartId, refetchCartData, t, addFlashMessage],
  )

  const cartItems = useMemo(
    () =>
      ((cartData?.cart?.items || []).filter(Boolean) ??
        []) as CartItemFragment[],
    [cartData?.cart?.items],
  )

  const contextValue = useMemo(
    () => ({
      cartItems,
      isAddingToCart,
      isCartRefetching: isLoadingCartData,
      isCartFetchError,
      hasItems: cartItems.length > 0,
      addToCart: addToCartWithCartId,
      refetchCartData,
    }),
    [
      cartItems,
      isAddingToCart,
      isCartFetchError,
      addToCartWithCartId,
      isLoadingCartData,
      refetchCartData,
    ],
  )

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
}

export const useCartContext = () => useContext(CartContext)
