'use client'

import { createContext, useContext, useState, useRef, useEffect } from 'react'
import { usePathname } from 'next/navigation'

import { SkeletonType } from '@/components/skeleton/PageSkeleton'
import { useStoreContext } from '../store'
import { getSkeletonTarget } from '@/common/utils'
import { FETCH_TIMEOUT } from '@/common/api/api.utils'

const SHOW_SKELETON_DELAY = 100

const SkeletonContext = createContext<{
  target: SkeletonType
  isVisible: boolean
  data: any
  hide: () => void
  show: ({ target, data }: ShowSkeletonProps) => void
}>({
  target: '',
  isVisible: false,
  data: {},
  show: () => {},
  hide: () => {},
})

export const useSkeletonContext = () => useContext(SkeletonContext)

export type ShowSkeletonProps = {
  target: SkeletonType
  url?: string | URL
  data?: any
}

export const SkeletonContextProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [data, setData] = useState<any>(undefined)
  const [target, setTarget] = useState<SkeletonType>('')
  const showTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const pathname = usePathname()
  const { storeCode } = useStoreContext()
  const refPathname = useRef<string>(pathname)
  const refTarget = useRef<SkeletonType>(getSkeletonTarget(storeCode, pathname))

  const show = ({ target, url, data }: ShowSkeletonProps) => {
    showTimeoutRef.current && clearTimeout(showTimeoutRef.current)

    if (!url || !target) {
      hideSkeleton()
      return
    }

    const targetPathname = new URL(url, 'http://localhost:3000').pathname

    if (
      refTarget.current === target &&
      refPathname.current === targetPathname
    ) {
      hideSkeleton()
      return
    }

    setData(data)
    setTarget(target)

    showTimeoutRef.current = setTimeout(showSkeleton, SHOW_SKELETON_DELAY)
  }

  const showSkeleton = () => {
    document.body.style.overflow = 'hidden'
    setIsVisible(true)

    hideTimeoutRef.current = setTimeout(hideSkeleton, FETCH_TIMEOUT)
  }

  const hideSkeleton = () => {
    hideTimeoutRef.current && clearTimeout(hideTimeoutRef.current)
    setIsVisible(false)
    document.body.style.overflow = 'visible'
  }

  const hide = () => {
    showTimeoutRef.current && clearTimeout(showTimeoutRef.current)
    hideSkeleton()
  }

  useEffect(() => {
    document.body.style.overflow = 'visible'

    return () => {
      setTarget('')
      hideSkeleton()

      if (showTimeoutRef.current) {
        clearTimeout(showTimeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    refPathname.current = pathname
    refTarget.current = getSkeletonTarget(storeCode, pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <SkeletonContext.Provider
      value={{
        target,
        isVisible,
        data,
        hide,
        show,
      }}
    >
      {children}
    </SkeletonContext.Provider>
  )
}
