import { HTMLAttributes, ReactNode } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

import { UIAlign } from '@/common/types/ui-types'

const variants = cva(
  ['flex', 'flex-1', 'items-center', 'justify-center', 'space-x-2'],
  {
    variants: {
      position: {
        start: ['flex-row'],
        end: ['flex-row-reverse'],
      },
      align: {
        left: ['justify-start'],
        center: ['justify-center'],
        right: ['justify-end'],
        between: ['justify-between'],
      },
    },
  },
)

export type LoaderProps = {
  position?: 'start' | 'end'
  loader?: boolean | ReactNode
  align?: UIAlign
  place?: 'global' | 'local'
} & HTMLAttributes<HTMLDivElement>

/**
 * @deprecated This component is deprecated and will be removed in future.
 * Please create skeleton loaders or use loader icon from /components/icons/svg/loader.tsx
 * Example:
 * <Button
 *   type="submit"
 *   variant="secondary"
 *   className="flex items-center gap-4"
 *   disabled={isPending}
 * >
 *   {isPending && <LoaderIcon className="animate-spin" />}
 *   <span>{t('save')}</span>
 * </Button>
 */
export const Loader = (props: LoaderProps) => {
  const { children, align, place, position, loader, className } = props

  return (
    <div className={twMerge(variants({ position, align }), className)}>
      {loader && (
        <span>
          {typeof loader === 'boolean' && (
            <div
              role="status"
              className={twMerge(
                place === 'local' ? 'absolute' : 'fixed',
                'text-black inset-0 z-20 bg-white/[0.55]',
              )}
            >
              <DefaultLoader />
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {typeof loader === 'object' && loader}
        </span>
      )}
      {children}
    </div>
  )
}

function DefaultLoader() {
  const styles = `circle#loading-spinner {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #000;
  }
  @keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 1;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
  }`

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      height="50px"
      viewBox="25 25 50 50"
      className="relative left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]"
    >
      <style>{styles}</style>
      <circle id="loading-spinner" cx="50" cy="50" r="23" fill="none" />
    </svg>
  )
}
