'use client'

import { PropsWithChildren } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'

import { StoreCodeType, StoreConfigType } from '@/common/types'
import { queryClient } from '@/services'
import { AuthContextProvider } from './auth'
import { CartContextProvider } from './cart'
import { StoreContextProvider } from './store'

interface ProvidersNotFoundProps {
  storeCode: StoreCodeType
  storeConfig: StoreConfigType
}

/** TODO: remove AuthContextProvider & CartContextProvider to use lightweight page layout when errros occur */
export const ProvidersNotFound = ({
  children,
  storeCode,
  storeConfig,
}: PropsWithChildren<ProvidersNotFoundProps>) => {
  return (
    <QueryClientProvider client={queryClient}>
      <StoreContextProvider storeCode={storeCode} storeConfig={storeConfig}>
        <AuthContextProvider>
          <CartContextProvider>{children}</CartContextProvider>
        </AuthContextProvider>
      </StoreContextProvider>
    </QueryClientProvider>
  )
}
