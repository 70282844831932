import { useEffect } from 'react'
import md5 from 'md5'

import { useCustomerBaseInfoQuery } from '@/api'
import {
  setCustomerSegment,
  // setCustomerGroupCode // TODO-GROUP
} from '../utils'
import { consoleError } from '@/common/utils/console'
import { isAccountUrl } from '@/common/utils'

interface HandleCustomerDataProps {
  customerToken: string | undefined
  resetAuth: () => void
}

/**
 * hook that handles the customer data, it also works as a signal for auth load to be completed.
 * @param customerToken
 * @param resetAuth
 */
export const useHandleCustomerData = ({
  customerToken,
  resetAuth,
}: HandleCustomerDataProps) => {
  const { isFetched, data } = useCustomerBaseInfoQuery(
    {},
    {
      enabled: !!customerToken,
    },
  )

  const customerData = data?.customer ?? undefined
  const customerGroupCode = customerData?.group_code

  useEffect(() => {
    if (customerToken && isFetched) {
      if (!customerGroupCode) {
        consoleError('use-handle-customer-data', {
          message: 'Failed at useCustomerBaseInfoQuery',
          customerToken,
          context: data,
        })
        resetAuth()
      } else {
        if (customerGroupCode) {
          // TODO: do this on server on login and cleanup on logout
          const hashed = md5(customerGroupCode)
          setCustomerSegment(hashed) // for caching purposes, store md5 hash of customer group name as cookie customerSegment
          // setCustomerGroupCode(customerGroupCode) // TODO-GROUP
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerToken, customerGroupCode, isFetched])

  useEffect(() => {
    if (!customerToken && isAccountUrl(window.location.pathname)) {
      consoleError('use-handle-customer-data', {
        message: 'Unauthorized: missing customerToken on account page',
        customerToken,
        context: data,
      })
      resetAuth()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerToken])

  return customerData
}
