'use client'

import { createContext, useContext, PropsWithChildren, RefObject } from 'react'

import { useLayout } from './hooks/use-layout'

export interface UiContextType {
  mobileTopBarHeight: number
  setHeaderRefCallback: (ref: RefObject<HTMLDivElement> | null) => void
}

export const UiContext = createContext<UiContextType>({
  mobileTopBarHeight: 0,
  setHeaderRefCallback: () => {},
})

export const UiContextProvider = ({ children }: PropsWithChildren) => {
  const { mobileTopBarHeight, setHeaderRefCallback } = useLayout()

  return (
    <UiContext.Provider value={{ mobileTopBarHeight, setHeaderRefCallback }}>
      {children}
    </UiContext.Provider>
  )
}

export const useUiContext = () => useContext(UiContext)
