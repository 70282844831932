import { useState, useEffect, useCallback } from 'react'
import { useTranslations } from 'next-intl'

import {
  AddConfigurableProductToCartMutation,
  AddGiftCardToCartMutation,
  GraphQlErrorsResponse,
  useAddConfigurableProductToCartMutation,
  useAddGiftCardToCartMutation,
} from '@/api'
import { FlashMessage, useFlashMessagesContext } from '@/providers'
import {
  CartErrors,
  processAddToCartErrors,
} from './process-add-to-cart-errors'
import { preserveMagentoStorageInvalidation } from '../utils'
import { AddToCartData } from '../cart.types'
import { HTTP_METHOD } from '@/common/api/api.utils'

type AddToCartParams = {
  cartId: string
  quantity: number
  sku: string
  selectedOptions?: string[]
  // onAddToCartError: () => Promise<void>
}

export const useAddToCart = () => {
  const t = useTranslations('FlashMessage')
  const [isCartFetchError, setIsCartFetchError] = useState(false)
  const [isAddingToCart, setIsAddingToCart] = useState(false)

  const { addFlashMessage } = useFlashMessagesContext()

  const { mutateAsync: addConfigurableProduct } =
    useAddConfigurableProductToCartMutation()
  const { mutateAsync: addGiftCard } = useAddGiftCardToCartMutation()

  const addGiftCardToCart = useCallback(
    async (
      { cartId, quantity, sku, selectedOptions }: AddToCartParams,
      internalErrorTranslation?: string,
    ) => {
      try {
        const res: AddGiftCardToCartMutation & GraphQlErrorsResponse =
          await addGiftCard({
            cartId,
            cartItems: [{ quantity, sku, selected_options: selectedOptions }],
          })

        if (res.errors) {
          fetch(`/api/v1/add-logs`, {
            method: HTTP_METHOD.POST,
            body: JSON.stringify({
              place: 'use-add-to-cart.tsx [addGiftCardToCart]',
              context: {
                message: 'Failed at addGiftCardToCart',
                cartId,
                cartItems: {
                  data: { quantity, sku },
                },
                errors: res.errors,
              },
            }),
          })

          return processAddToCartErrors(
            res.errors as CartErrors[],
            internalErrorTranslation,
          )
        }

        return [] as FlashMessage[]
      } catch (err) {
        fetch(`/api/v1/add-logs`, {
          method: HTTP_METHOD.POST,
          body: JSON.stringify({
            place: 'use-add-to-cart.tsx [addGiftCardToCart][catch]',
            context: {
              message: 'Failed at addGiftCardToCart',
              cartId,
              cartItems: {
                quantity,
                sku,
                selectedOptions,
              },
              errors: err,
            },
          }),
        })

        return [
          { severity: 'error', text: internalErrorTranslation },
        ] as FlashMessage[]
      }
    },
    [addGiftCard],
  )

  const addConfigToCart = useCallback(
    async (
      {
        cartId,
        quantity,
        sku,
        parentSku,
      }: AddToCartParams & { parentSku?: string },
      internalErrorTranslation?: string,
    ) => {
      try {
        const res: AddConfigurableProductToCartMutation &
          GraphQlErrorsResponse = await addConfigurableProduct({
          cartId,
          cartItems: [{ parent_sku: parentSku, data: { quantity, sku } }],
        })

        if (res.errors) {
          fetch(`/api/v1/add-logs`, {
            method: HTTP_METHOD.POST,
            body: JSON.stringify({
              place: 'use-add-to-cart.tsx [addConfigToCart]',
              context: {
                message: 'Failed at addConfigToCart',
                cartId,
                cartItems: {
                  parent_sku: parentSku,
                  data: { quantity, sku },
                },
                errors: res.errors,
              },
            }),
          })

          return processAddToCartErrors(
            res.errors as CartErrors[],
            internalErrorTranslation,
          )
        }

        return (res.addConfigurableProductsToCart?.messages ?? []).map(
          (message) => ({
            severity: message?.type as FlashMessage['severity'],
            text: message?.message as string,
          }),
        )
      } catch (err) {
        fetch(`/api/v1/add-logs`, {
          method: HTTP_METHOD.POST,
          body: JSON.stringify({
            place: 'use-add-to-cart.tsx [addConfigToCart][catch]',
            context: {
              message: 'Failed at addConfigToCart',
              cartId,
              cartItems: {
                parent_sku: parentSku,
                data: { quantity, sku },
              },
              cause: {
                message: err?.message,
                cause: err?.cause,
              },
            },
          }),
        })

        return [
          { severity: 'error', text: internalErrorTranslation },
        ] as FlashMessage[]
      }
    },
    [addConfigurableProduct],
  )

  const addToCart = async ({
    sku,
    cartId,
    quantity,
    parentSku,
    isGiftCard,
    productName,
    selectedOptions,
  }: AddToCartData) => {
    try {
      setIsCartFetchError(false)
      setIsAddingToCart(true)

      const messages: FlashMessage[] = await (isGiftCard
        ? addGiftCardToCart(
            {
              cartId,
              quantity,
              sku,
              selectedOptions,
            },
            t('errorAddProductToCart'),
          )
        : addConfigToCart(
            {
              cartId,
              quantity,
              sku,
              parentSku,
            },
            t('errorAddProductToCart'),
          ))

      const isError = messages.some((message) => message.severity === 'error')

      /*
       * addConfigToCart and addGiftCardToCart functions are not throwing any error
       * they are catching all errors from mutations and returning flash messages
       * if any of these messages have error in them we show them and then throw error
       * this error will be handled in the upper function in cart context for better readability
       */
      if (isError) {
        fetch(`/api/v1/add-logs`, {
          method: HTTP_METHOD.POST,
          body: JSON.stringify({
            place: 'use-add-to-cart.tsx [addToCart]',
            context: {
              message: `Failed at ${
                isGiftCard ? 'addGiftCardToCart' : 'addConfigToCart'
              }`,
              sku,
              cartId,
              quantity,
              parentSku,
              isGiftCard,
              productName,
              selectedOptions,
              cause: messages,
            },
          }),
        })

        messages.forEach((message) => addFlashMessage(message))
        throw Error(messages.join(', '))
      }
    } catch (error) {
      setIsCartFetchError(true)

      throw Error(error)
    } finally {
      setIsAddingToCart(false)
    }
  }

  /**
   * add info for magneto cart reload into the LocalStorage with structure:
   * mage-cache-storage-section-invalidation: {"cart": ${currentTimestamp}}
   */
  useEffect(() => {
    const now = new Date().getTime()
    preserveMagentoStorageInvalidation(now)
  }, [])

  return {
    addToCart,
    isAddingToCart,
    isCartFetchError,
  }
}
