'use client'

import {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
  useEffect,
  useState,
  useCallback,
} from 'react'

import { CustomerBaseInfoQuery, fetchGuestToken } from '@/api'
import { useHandleCustomerData } from './hooks'
import {
  getCustomerToken,
  // removeCustomerGroupCode, // TODO-GROUP
  removeCustomerSegment,
  removeCustomerToken,
} from './utils'
import { IS_NEXT_AUTH, isAccountUrl } from '@/common/utils'

interface AuthContext {
  isLoggedIn: boolean
  loaded: boolean
  guestToken: string | undefined
  customerToken: string | undefined
  customerData: CustomerBaseInfoQuery['customer']
}

export const AuthContext = createContext<AuthContext>({
  isLoggedIn: false,
  loaded: false,
  guestToken: undefined,
  customerToken: undefined,
  customerData: undefined,
})

/**
 * The Provider handles users token and customer data
 * @param children
 * @constructor
 */
export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [guestToken, setGuestToken] = useState<string | undefined>(undefined)
  const customerToken = getCustomerToken()

  const resetAuth = useCallback(() => {
    if (IS_NEXT_AUTH) {
      removeCustomerToken()
      removeCustomerSegment()
      // removeCustomerGroupCode() // TODO-GROUP

      requestAnimationFrame(() => {
        if (!window.location.pathname.includes('/customer/account/login')) {
          history.pushState({}, '', '/customer/account/login')
          window.location.reload()
        }
      })
    } else {
      if (isAccountUrl(window.location.pathname)) {
        requestAnimationFrame(() => {
          if (!window.location.pathname.includes('/customer/account/login')) {
            history.pushState({}, '', '/customer/account/login')
            window.location.reload()
          }
        })
      }
    }
  }, [])

  const fetchNewGuestToken = useCallback(async () => {
    const guestToken = await fetchGuestToken()

    if (guestToken) {
      setGuestToken(guestToken)
    }
  }, [])

  useEffect(() => {
    if (!guestToken && !customerToken) {
      fetchNewGuestToken()
    }
  }, [customerToken, fetchNewGuestToken, guestToken])

  const customerData = useHandleCustomerData({
    customerToken,
    resetAuth,
  })

  const contextValue: AuthContext = useMemo(
    () => ({
      isLoggedIn: !!customerData,
      loaded: !!(guestToken ?? customerData),
      guestToken,
      customerToken,
      customerData,
    }),
    [guestToken, customerToken, customerData],
  )

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
