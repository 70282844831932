const Breakpoints = {
  usm: '480px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  desktop: '1440px',
  '2xl': '1536px',
}

const colorPalette = {
  grey: {
    100: '#dfdedd',
    200: '#a8a69f',
    300: '#959595',
    400: '#757779',
    DEFAULT: '#6c6c6c',
    500: '#575757',
    600: '#4a4948',
  },
  primary: {
    DEFAULT: '#000',
  },
  secondary: {
    DEFAULT: '#ff4100',
    disabled: '#ffa080',
    hover: '#cc3400',
    strong: '#c23500',
  },
  success: {
    DEFAULT: '#8dc63f',
    strong: '#567B24',
  },
  alert: {
    success: {
      'bg-color': '#e5efe5',
      'fill-color': '#006400',
      'text-color': '#006400',
    },
    warning: {
      'bg-color': '#fdf0d5',
      'fill-color': '#c07600',
      'text-color': '#6f4400',
    },
    error: {
      'bg-color': '#fae5e5',
      'fill-color': '#b30000',
      'text-color': '#e02b27',
    },
  },
  'ribbon-default-bg-color': '#000',
  'ribbon-default-font-color': '#fff',
  'tooltip-text': '#333',
  'tooltip-border': '#bbb',
  shadow: '#00000073',
  'google-logo': '#808080',
  skeleton: '#e2e8f0',
}

/** @type {import('tailwindcss').Config} */
const TailwindConfig = {
  mode: 'jit',

  theme: {
    screens: Breakpoints,
    extend: {
      fontFamily: {
        body: ['Open Sans'],
      },
      height: {
        field: '28px',
      },
      animation: {
        fadeIn: 'fadeIn 0.5s ease-in-out',
        slideUp: 'slideUp 0.25s ease',
        slideDown: 'slideDown 0.3s ease',
        slideDownStraight: 'slideDownStraight 0.25s ease',
        flashMessage: 'showMessage 5s ease-in-out infinite',
      },

      gridTemplateColumns: {
        'footer-blocks': '5fr 5fr 6fr 5fr',
      },

      keyframes: () => ({
        fadeIn: {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 },
        },
        showMessage: {
          '0%': { opacity: 0, height: 0, transform: 'translateY(-20%)' },
          '10%': { opacity: 0 },
          '20%': { opacity: 1, height: 'auto', transform: 'translateY(0%)' },
          '80%': { opacity: 1, height: 'auto', transform: 'translateY(0%)' },
          '90%': { opacity: 0 },
          '100%': { opacity: 0, height: 0, transform: 'translateY(-20%)' },
        },
        slideUp: {
          '0%': { transform: 'translateY(0)' },
          '100%': { transform: 'translateY(-30rem)' },
        },
        slideDown: {
          '0%': { transform: 'translate(-50%, -30rem)' },
          '100%': { transform: 'translate(-50%, 0)' },
        },
        slideDownStraight: {
          '0%': { transform: 'translateY(-30rem)' },
          '100%': { transform: 'translateY(0)' },
        },
      }),
      colors: {
        ...colorPalette,
      },
    },
  },
}

module.exports = TailwindConfig
module.exports.Breakpoints = Breakpoints
module.exports.colorPalette = colorPalette
