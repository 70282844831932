'use client'

import { PropsWithChildren } from 'react'
import { QueryClientProvider, HydrationBoundary } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { queryClient } from '@/services'
import { StoreCodeType, StoreConfigType } from '@/common/types'
import { AuthContextProvider } from './auth'
import { CartContextProvider } from './cart'
import { ReCaptchaProvider } from './re-captcha'
import { FlashMessagesProvider } from './flash-messages'
import { FullScreenSpinnerProvider } from './full-screen-spinner'
import { UiContextProvider } from './ui/ui-context'
import { SkeletonContextProvider } from './skeleton/skeleton-context'
import { StoreContextProvider } from './store'

interface ProvidersProps {
  storeCode: StoreCodeType
  storeConfig: StoreConfigType
}

export function Providers({
  children,
  storeCode,
  storeConfig,
}: PropsWithChildren<ProvidersProps>) {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <StoreContextProvider storeCode={storeCode} storeConfig={storeConfig}>
        <FlashMessagesProvider>
          <HydrationBoundary>
            <ReCaptchaProvider>
              <SkeletonContextProvider>
                <FullScreenSpinnerProvider>
                  <AuthContextProvider>
                    <CartContextProvider>
                      <UiContextProvider>{children}</UiContextProvider>
                    </CartContextProvider>
                  </AuthContextProvider>
                </FullScreenSpinnerProvider>
              </SkeletonContextProvider>
            </ReCaptchaProvider>
          </HydrationBoundary>
        </FlashMessagesProvider>
      </StoreContextProvider>
    </QueryClientProvider>
  )
}
